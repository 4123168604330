import ApiService from "@/core/services/api.service";

let url = process.env.VUE_APP_API_ADMIN;

class Users {
  get(id) {
    return ApiService.get(url, "users/" + id);
  }

  getAll(params, optionalHeaders = {}) {
    let options = new URLSearchParams(params).toString();
    return ApiService.get(url, "users?" + options, optionalHeaders);
  }

  store(data) {
    return ApiService.post(url + "/users", data);
  }

  update(userId, data) {
    return ApiService.put(url + "/users/" + userId, data);
  }

  delete(id) {
    return ApiService.delete(url + "/users/" + id);
  }
}

export default new Users();
