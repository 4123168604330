<template>
  <div>
    <Header
      :title="$t('users.userDetails').toString()"
      :show-back-button="true"
      :items="headerButtons"
      @back="$router.push({ name: 'projectSettingsUsers' })"
      @save="save"
    />
    <div class="card card-custom">
      <div class="card-body">
        <FormHelper
          ref="form"
          v-model="user"
          :form="baseDataForm"
          :config="baseDataFormConfig"
        />
        <FormHelper
          ref="form"
          v-model="user"
          :form="profileForm"
          :config="profileFormConfig"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Users from "@/components/Settings/Users/users";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import Languages from "@/components/General/Languages/languages";
import { mapGetters } from "vuex";

export default {
  name: "ProjectUsersDetails",
  components: {
    Header,
    FormHelper
  },
  data() {
    return {
      baseDataFormConfig: {
        title: this.$t("users.baseData"),
        snippetPrefix: "user",
        enableVariables: false
      },
      baseDataForm: [
        {
          label: this.$t("users.salutation"),
          name: "salutation",
          type: "text",
          disableReturnType: true,
          placeholder: this.$t("users.enterSalutation"),
          validations: {
            minLength: 2
          }
        },
        {
          label: this.$t("users.title"),
          name: "title",
          type: "text",
          disableReturnType: true,
          placeholder: this.$t("users.enterTitle"),
          validations: {
            minLength: 2
          }
        },
        {
          label: this.$t("users.firstName"),
          name: "firstname",
          type: "text",
          disableReturnType: true,
          placeholder: this.$t("users.enterFirstName"),
          validations: {
            required: true,
            minLength: 2
          }
        },
        {
          label: this.$t("users.lastName"),
          name: "lastname",
          type: "text",
          disableReturnType: true,
          placeholder: this.$t("users.enterLastName"),
          validations: {
            required: true,
            minLength: 2
          }
        },
        {
          label: this.$t("users.email"),
          name: "email",
          type: "text",
          disableReturnType: true,
          placeholder: this.$t("users.enterEmail"),
          validations: {
            required: true,
            minLength: 2
          }
        },
        {
          label: this.$t("general.active"),
          name: "active",
          type: "checkbox"
        },
        {
          label: this.$t("users.admin"),
          name: "is_admin",
          type: "checkbox"
        }
      ],
      profileFormConfig: {
        title: this.$t("users.profile"),
        snippetPrefix: "user",
        enableVariables: false
      },
      profileForm: [
        {
          label: this.$t("users.password"),
          name: "password",
          type: "password",
          placeholder: this.$t("users.enterPassword"),
          disableReturnType: true,
          validations: {
            minLength: 2
          }
        },
        {
          label: this.$t("users.passwordConfirmation"),
          name: "passwordConfirmation",
          type: "password",
          placeholder: this.$t("users.enterPasswordConfirmation"),
          disableReturnType: true,
          validations: {
            minLength: 2
          }
        },
        {
          label: this.$t("users.language"),
          name: "language_id",
          type: "select",
          options: [],
          default: this.$store.getters.userData.language_id
        }
      ],
      user: {
        core_data: {
          salutation: "",
          title: "",
          firstname: "",
          lastname: ""
        },
        email: "",
        active: true,
        language_id: this.$store.getters.userData.language_id,
        salutation: "",
        title: "",
        firstname: "",
        lastname: ""
      }
    };
  },
  computed: {
    ...mapGetters(["selectedPresetVersion", "isDevPresetVersion"]),
    headerButtons: function () {
      if (!this.isDevPresetVersion) {
        return [];
      }
      return [
        {
          type: "button",
          title: this.$t("general.save"),
          emit: "save",
          disabledWhenLoading: true
        }
      ];
    }
  },
  watch: {
    selectedPresetVersion: function () {
      this.loadUser();
    }
  },
  mounted() {
    this.loadUser();
  },
  methods: {
    getLanguages() {
      addEventToLoadingQueue({ key: "getLanguages" });
      Languages.getAll()
        .then(response => {
          this.setLanguageOptions(response.data);
          removeEventFromLoadingQueue({ key: "getLanguages" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    setLanguageOptions(languages) {
      const formLanguages = Object.values(this.profileForm).find(
        element => element.name === "language_id"
      );

      formLanguages.options = languages.map(lang => ({
        label: lang.label,
        value: lang.id
      }));
    },
    loadUser() {
      addEventToLoadingQueue({ key: "loadUser" });

      let userId = this.$route.params.id;
      Users.get(userId)
        .then(response => {
          const user = response.data;

          this.user = user;

          this.user.salutation = user.core_data?.salutation ?? "";
          this.user.title = user.core_data?.title ?? "";
          this.user.firstname = user.core_data?.firstname;
          this.user.lastname = user.core_data?.lastname;
          this.user.name = user.name;
          this.getLanguages();
          removeEventFromLoadingQueue({ key: "loadUser" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    save() {
      addEventToLoadingQueue({ key: "saveUser" });
      // Set necessary user data
      if (!this.user.core_data) {
        this.user.firstname = this.user.name.split(" ")[0];
        this.user.lastname = this.user.name.split(" ")[1];
        this.user.core_data = {};
      }
      this.user.name = this.user.firstname + " " + this.user.lastname;
      this.user.core_data.salutation = this.user.salutation;
      this.user.core_data.title = this.user.title;
      this.user.core_data.firstname = this.user.firstname;
      this.user.core_data.lastname = this.user.lastname;

      Users.update(this.$route.params.id, this.user)
        .then(() => {
          removeEventFromLoadingQueue({
            key: "saveUser",
            type: "success",
            prefix: "users",
            name: "userUpdated"
          });
          this.$router.push({ name: "projectSettingsUsers" });
        })
        .catch(error => {
          this.$error(error);
        });
    }
  }
};
</script>
